import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import Container from "../components/Container";

import ImageOne from "../assets/images/Diffirentieel.webp";
import ImageTwo from "../assets/images/stopcontacten.webp";
import ImageThree from "../assets/images/Sturing.webp";
import seoImage from "../assets/logo/Stetrics-logo-og.webp";
import ButtonStyles from "../styles/ButtonStyles";

const SingleCityPageStyles = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0 100px 0;

  h1 {
    @media (min-width: 920px) {
      font-size: 3.5em;
    }
  }

  h3 {
    color: var(--black);
    text-align: left;
  }

  p {
    padding: 20px 0;
    strong {
      font-weight: bold;
    }
  }
`;

const ImageContainer = styled.section`
  max-width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  img {
    max-width: 100%;
  }
`;

const ImageItem = styled.div`
  width: 30%;
`;

const WorkContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 30px 0;
  position: relative;

  @media (min-width: 440px) {
    flex-direction: row;
  }
`;

const WorkItem = styled.div`
  width: 100%;
  padding: 30px;

  @media (min-width: 850px) {
    padding-top: 0px;
    width: 50%;
  }

  h2,
  h3 {
    color: var(--black);
    text-align: left;
    padding: 0;
    font-size: 1.2em;

    @media (min-width: 440px) {
      font-size: 1.5em;
    }
  }

  p {
    text-align: left;

    strong {
      font-weight: normal;
    }
  }

  ol {
    margin: 0;
    padding-left: 25px;

    li {
      list-style-type: circle;
      text-align: left;
      padding: 5px;
    }
  }
`;

const SingleCityPage = ({ data: { mdx: city }, location }) => {
  const { title } = city.frontmatter;

  const { pathname } = location;

  const seoLocation = {
    pathname: pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
  }


  const description = `Elektricien ${title} - Elektriciteitswerken ${title}: op zoek naar een elektricien voor uw elektriciteitswerken? Stetrics helpt U...`;

  return (
    <Container>
      <SingleCityPageStyles>
        <SEO title={`Elektricien ${title} - Elektriciteitswerken ${title}`} location={seoLocation} description={description} image={seoImage} />
        <h1>Elektricien {title}</h1>

        <article>
          <p>
            Op zoek naar een erkend elektricien in {title}? Stetrics is een zaak die sinds 2020 ervaring heeft in algemene elektriciteitswerken, verlichting en vernieuwen van een elektrische
            installaties regio {title}.
          </p>
          <p>
            Graag maak Ik voor iedere klant de nodige tijd en ruimte vrij om een dienstverlening op maat te kunnen aanbieden. Als zaakvoerder ben ik betrokken van bij het eerste contact tot aan de
            oplevering van de werken en draag ik een eerlijke en transparante communicatie hoog in het vaandel.
          </p>
          <p>Voor zowel kleine als middelgrote elektriciteitswerken kunt U voortaan terecht bij:</p>
          <h3 style={{ textAlign: "center" }}>STETRICS</h3>
          <p>Jouw partner voor gestroomlijnde elektriciteitswerken</p>
          <Link to='/contact/'>
            <ButtonStyles>Contacteer ons</ButtonStyles>
          </Link>
        </article>

        <ImageContainer>
          <ImageItem>
            <img src={ImageOne} alt='Een rij van diffirentieel automaten' />
          </ImageItem>
          <ImageItem>
            <img src={ImageTwo} alt='opbouw stopcontacten zonder voorplaat' />
          </ImageItem>
          <ImageItem>
            <img src={ImageThree} alt='Electrische sturingsmodule' />
          </ImageItem>
        </ImageContainer>

        <hr className='stetrics-hr' />

        <WorkContainer>
          <WorkItem>
            <h2>Elektriciteitswerken {title}</h2>
            <p>Renovatie en nieuwbouw: Voor particulieren, zakelijke klanten.</p>
            <ol>
              <li>Algemene elektriciteitswerken</li>
              <li>Verlichting en tuinverlichting</li>
              <li>Elektriciteitsplannen maken</li>
              <li>Elektrische installaties volgens AREI</li>
              <li>Elektriciteit bij nieuwbouw</li>
              <li>Renovatie en uitbreiding elektriciteit installatie</li>
              <li>Plaatsing van verlichting, buitenverlichting en tuinverlichting</li>
              <li>Ondersteuningswerken bij nieuwe elektrische installaties</li>
              <li>Alle algemene elektriciteitswerken</li>
            </ol>
          </WorkItem>

          <WorkItem>
            <h2>Elektricien nodig voor herstelling?</h2>
            <p>U kunt bij ons ook terecht voor herstellingen of reparaties regio&nbsp;{title}. Zowel grote als kleine werken & herstellingen zijn een kolfje naar onze hand.</p>
            <ol>
              <li>Kapotte lichtschakelaar</li>
              <li>Lichtpunten verleggen</li>
              <li>Stroomstoringen</li>
              <li>Kortsluiting</li>
              <li>Herstelling zekeringskast</li>
              <li>Keuringsverslagen</li>
              <li>…</li>
            </ol>
          </WorkItem>
        </WorkContainer>
        <hr className='stetrics-hr' />
        <WorkContainer>
          <WorkItem>
            <h2>Elektriciteitswerken {title}</h2>
            <p>Voor alles wat met elektriciteit te maken heeft, kunt u bij ons terecht.</p>
            <p>Binnen, buiten, voor particulieren, winkels, bedrijven of de industrie. Of u het nu gaat om een totaalproject of om een kleine herstelling, wij doen het allemaal!</p>
            <p>Aarzel niet om ons vrijblijvend te contacteren of om een afspraak te maken en we doen ons best om u zo snel mogelijk verder te helpen.</p>
          </WorkItem>

          <WorkItem>
            <h2>Let op!</h2>
            <p>Ga niet zelf uw elektrische installaties aanpassen. Als u iets fout doet, is het levensgevaarlijk.</p>
            <p>Heeft u een probleem? Elektricien Stetrics weet er raad mee. Bent u uit {title}? Dan heeft u geluk! U kunt rekenen op onze snelle en deskundige service!</p>
          </WorkItem>
        </WorkContainer>
      </SingleCityPageStyles>
    </Container>
  );
};

SingleCityPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.any,
};

export default SingleCityPage;

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        slug
        title
      }
    }
  }
`;
